import * as Yup from "yup"
import { standardRequiredText } from "../../constants"

export const validationSchema = Yup.object().shape({
  understand: Yup.object().shape({
    overtime: Yup.number().oneOf([1], standardRequiredText),
    shift_patterns: Yup.number().oneOf([1], standardRequiredText),
    pay_rates: Yup.number().oneOf([1], standardRequiredText),
    time_sheets_and_payslips: Yup.number().oneOf([1], standardRequiredText),
    uniform_and_ppe: Yup.number().oneOf([1], standardRequiredText),
    mobile_phone_policy: Yup.number().oneOf([1], standardRequiredText),
    smoking_policy: Yup.number().oneOf([1], standardRequiredText),
    car_park_and_security: Yup.number().oneOf([1], standardRequiredText),
    shut_downs_and_holidays: Yup.number().oneOf([1], standardRequiredText),
    absence_policy: Yup.number().oneOf([1], standardRequiredText),
    full_job_description: Yup.number().oneOf([1], standardRequiredText),
    recruitment_process_and_line_trial: Yup.number().oneOf(
      [1],
      standardRequiredText
    ),
    company_information: Yup.number().oneOf([1], standardRequiredText),
    dignity_unacceptable_behaviours: Yup.number().oneOf(
      [1],
      standardRequiredText
    ),
    ppe_jewellery: Yup.number().oneOf([1], standardRequiredText),
    health_and_safety: Yup.number().oneOf([1], standardRequiredText),
    coshh: Yup.number().oneOf([1], standardRequiredText),
    site_hse_policy: Yup.number().oneOf([1], standardRequiredText),
    basic_plant_rules: Yup.number().oneOf([1], standardRequiredText),
    fire_evacuation: Yup.number().oneOf([1], standardRequiredText),
    manual_handling_information: Yup.number().oneOf([1], standardRequiredText),
    occupational_health: Yup.number().oneOf([1], standardRequiredText),
    environmental_information: Yup.number().oneOf([1], standardRequiredText),
    noise_at_work: Yup.number().oneOf([1], standardRequiredText),
    what_is_mdi: Yup.number().oneOf([1], standardRequiredText),
    drug_and_alcohol_policy: Yup.number().oneOf([1], standardRequiredText),
  }),
  pre_screen_questionnaire: Yup.object().shape({
    question_1: Yup.string().required(standardRequiredText),
    question_2: Yup.string().required(standardRequiredText),
    question_3: Yup.string().required(standardRequiredText),
    question_4: Yup.string().required(standardRequiredText),
    question_5: Yup.string().required(standardRequiredText),
    question_6: Yup.string().required(standardRequiredText),
    question_7: Yup.string().required(standardRequiredText),
    question_8: Yup.string().required(standardRequiredText),
    question_9: Yup.string().required(standardRequiredText),
    question_10: Yup.string().required(standardRequiredText),
    question_11: Yup.string().required(standardRequiredText),
    question_12: Yup.string().required(standardRequiredText),
  }),
  happy_for_line_trial_participation: Yup.number().oneOf(
    [1],
    standardRequiredText
  ),
})
