export const initialValues = {
  understand: {
    overtime: 0,
    shift_patterns: 0,
    pay_rates: 0,
    time_sheets_and_payslips: 0,
    uniform_and_ppe: 0,
    mobile_phone_policy: 0,
    smoking_policy: 0,
    car_park_and_security: 0,
    shut_downs_and_holidays: 0,
    absence_policy: 0,
    full_job_description: 0,
    recruitment_process_and_line_trial: 0,
    company_information: 0,
    dignity_unacceptable_behaviours: 0,
    ppe_jewellery: 0,
    health_and_safety: 0,
    coshh: 0,
    site_hse_policy: 0,
    basic_plant_rules: 0,
    fire_evacuation: 0,
    manual_handling_information: 0,
    occupational_health: 0,
    environmental_information: 0,
    noise_at_work: 0,
    what_is_mdi: 0,
    drug_and_alcohol_policy: 0,
  },
  pre_screen_questionnaire: {
    question_1: "",
    question_2: "",
    question_3: "",
    question_4: "",
    question_5: "",
    question_6: "",
    question_7: "",
    question_8: "",
    question_9: "",
    question_10: "",
    question_11: "",
    question_12: "",
  },
  happy_for_line_trial_participation: 0,
}
