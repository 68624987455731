import React, { useState } from "react"
import { Formik } from "formik"
import { Helmet } from "react-helmet"

import { dataURItoBlob } from "../../utils/form-data"
import {
  submitForm,
  handleSubmitResponse,
  jsonToFormData,
} from "../../services"

import {
  Col2,
  LCard,
  FieldSet,
  CardGrey,
  HeaderWithLogo,
} from "../../style/containers"
import { Table, TableEqualColumns, Std, Sth } from "../../style/tables"
import LearLogo from "../../assets/images/logos/lear-corporation-logo.jpg"

import Layout from "../../components/layout"
import SButton from "../../components/button"
import Signature from "../../components/Signature"
import { CheckboxGroup } from "../../components/form/checkbox"
import Form from "../../components/form/FormHelper"
import { FormikCheckbox, FormikText } from "../../components/form"
import {
  initialValues,
  validationSchema,
} from "../../components/LearNewStarter"

const LearNewStarter = () => {
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)
      let res = await submitForm(formData, "")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lear New Starter Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <HeaderWithLogo>
                <h1>Lear New Starter Form</h1>
                <img src={LearLogo} alt="Lear Logo" />
              </HeaderWithLogo>
              <h2>Pin Point Induction Checklist</h2>
              <FieldSet>
                <CheckboxGroup>
                  <b>
                    I can confirm the following points have been explained to me
                    and I understand them fully. I commit to adhere to them
                    throughout my assignment at Lear Corporation:
                  </b>
                  <br />
                  <FormikCheckbox label="Overtime" name="understand.overtime" />
                  <FormikCheckbox
                    label="Shift Patterns"
                    name="understand.shift_patterns"
                  />
                  <FormikCheckbox
                    label="Pay Rates and Over Time Rates"
                    name="understand.pay_rates"
                  />
                  <FormikCheckbox
                    label="Time Sheets and Payslips"
                    name="understand.time_sheets_and_payslips"
                  />
                  <FormikCheckbox
                    label="Uniform and PPE "
                    name="understand.uniform_and_ppe"
                  />
                  <FormikCheckbox
                    label="Mobile Phone Policy"
                    name="understand.mobile_phone_policy"
                  />
                  <FormikCheckbox
                    label="Smoking Policy"
                    name="understand.smoking_policy"
                  />
                  <FormikCheckbox
                    label="Car Park and Security"
                    name="understand.car_park_and_security"
                  />
                  <FormikCheckbox
                    label="Shut down periods/Holidays and Lieu Days"
                    name="understand.shut_downs_and_holidays"
                  />
                  <FormikCheckbox
                    label="Absence Policy and Procedure"
                    name="understand.absence_policy"
                  />
                  <FormikCheckbox
                    label="Full Job Description"
                    name="understand.full_job_description"
                  />
                  <FormikCheckbox
                    label="Recruitment Process and Line Trial Explained"
                    name="understand.recruitment_process_and_line_trial"
                  />
                  <FormikCheckbox
                    label="Company Information"
                    name="understand.company_information"
                  />
                  <FormikCheckbox
                    label="Dignity at Work and Unacceptable Behaviours"
                    name="understand.dignity_unacceptable_behaviours"
                  />
                  <FormikCheckbox
                    label="PPE and Jewellery"
                    name="understand.ppe_jewellery"
                  />
                  <FormikCheckbox
                    label="Your health, Safety and Environmental Responsibilities"
                    name="understand.health_and_safety"
                  />
                  <FormikCheckbox
                    label="Understanding COSHH"
                    name="understand.coshh"
                  />
                  <FormikCheckbox
                    label="Site HSE Policy"
                    name="understand.site_hse_policy"
                  />
                  <FormikCheckbox
                    label="Basic Plant Rules"
                    name="understand.basic_plant_rules"
                  />
                  <FormikCheckbox
                    label="Fire Evacuation"
                    name="understand.fire_evacuation"
                  />
                  <FormikCheckbox
                    label="Manual Handling Information"
                    name="understand.manual_handling_information"
                  />
                  <FormikCheckbox
                    label="Occupational Health"
                    name="understand.occupational_health"
                  />
                  <FormikCheckbox
                    label="Environmental Information"
                    name="understand.environmental_information"
                  />
                  <FormikCheckbox
                    label="Noise at Work"
                    name="understand.noise_at_work"
                  />
                  <FormikCheckbox
                    label="What is MDI"
                    name="understand.what_is_mdi"
                  />
                </CheckboxGroup>
                <h2>Drugs and Alcohol Policy</h2>
                <p>
                  The following list although not exhaustive may give an
                  indication that an employee is under the influence of alcohol
                  and drugs.
                </p>
                <p>Signs of current intoxication:</p>
                <ul>
                  <li>Smell of alcohol</li>
                  <li>Unsteadiness</li>
                  <li>Mood swings including aggressive behaviour</li>
                  <li>Appearing to be unaware or detached from reality</li>
                  <li>Incoherent speech or slurred words</li>
                  <li>
                    Poor communication and inappropriate comments to colleagues
                  </li>
                  <li>Anxious and irritable</li>
                  <li>Glazed eyes</li>
                  <li>Smell of alcohol</li>
                  <li>Poor attendance and timekeeping</li>
                  <li>
                    Erratic period of absence with no apparent underlying cause
                  </li>
                  <li>Poor work performance</li>
                  <li>Accident prone</li>
                  <li>Overall presentation of employee is deteriorating</li>
                  <li>Lack of concentration</li>
                  <li>Argumentative and irritable</li>
                  <li>Dramatic changes in a previously reliable employee</li>
                </ul>
                <br />
                <FormikCheckbox
                  label="I can confirm I have read and understand the signs of intoxication"
                  name="understand.drug_and_alcohol_policy"
                  bold={true}
                />
              </FieldSet>
              <h2>Confirmation of Temporary Assignment</h2>
              <FieldSet>
                <p>
                  This form is to confirm you will be working on a contract of
                  services via Pin Point Recruitment at Lear Corporation, on a
                  temporary contract:
                </p>
                <Table>
                  <tbody>
                    <tr>
                      <Std>
                        <b>Position:</b>
                      </Std>
                      <Std>Production Operative</Std>
                    </tr>
                    <tr>
                      <Std>
                        <b>Location:</b>
                      </Std>
                      <Std>
                        Lear Corporation, Phoenix Way, Rainton Bridge Industrial
                        Estate, DH4 5SA
                      </Std>
                    </tr>
                    <tr>
                      <Std>
                        <b>Reporting To:</b>
                      </Std>
                      <Std>Shift Supervisor</Std>
                    </tr>
                    <tr>
                      <Std>
                        <b>Contract Length:</b>
                      </Std>
                      <Std>Ongoing</Std>
                    </tr>
                    <tr>
                      <Std>
                        <b>Training:</b>
                      </Std>
                      <Std>
                        Colleagues will take part in a line trial on site with
                        the client where they will be introduced to the job role
                        and will cover all necessary health and safety
                        information with on-site trainer.
                      </Std>
                    </tr>
                    <tr>
                      <Std>
                        <b>Job Spec:</b>
                      </Std>
                      <Std>
                        Operating machinery and small hand tools to manufacture
                        car seats to strict specifications within a busy, fast
                        paced factory. You are responsible for your own quality
                        of work and ensuring the specifications are always met.
                      </Std>
                    </tr>
                    <tr>
                      <Std>
                        <b>PPE:</b>
                      </Std>
                      <Std>
                        Safety boots/trainers, plain black or navy polo top or
                        t-shirt, black or navy work trousers.
                      </Std>
                    </tr>
                  </tbody>
                </Table>
                <br />
                <h3>Shift times</h3>
                <TableEqualColumns>
                  <thead>
                    <tr>
                      <Sth>JIT Line 1-2 Shift</Sth>
                      <Sth>Monday</Sth>
                      <Sth>Tuesday</Sth>
                      <Sth>Wednesday</Sth>
                      <Sth>Thursday</Sth>
                      <Sth>Friday</Sth>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <Std>
                        <b>Day Shift</b>
                      </Std>
                      <Std>07:15 - 15:30</Std>
                      <Std>07:15 - 15:30</Std>
                      <Std>07:15 - 15:30</Std>
                      <Std>07:15 - 15:30</Std>
                      <Std>07:15 - 15:00</Std>
                    </tr>
                    <tr>
                      <Std>
                        <b>Late shift</b>
                      </Std>
                      <Std>16:45 - 01:00</Std>
                      <Std>16:45 - 01:00</Std>
                      <Std>16:45 - 01:00</Std>
                      <Std>16:45 - 01:00</Std>
                      <Std>15:20 - 23:05</Std>
                    </tr>
                  </tbody>
                </TableEqualColumns>
                <br />
                <TableEqualColumns>
                  <thead>
                    <tr>
                      <Sth>JIT Line 2-2 Shift</Sth>
                      <Sth>Monday</Sth>
                      <Sth>Tuesday</Sth>
                      <Sth>Wednesday</Sth>
                      <Sth>Thursday</Sth>
                      <Sth>Friday</Sth>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <Std>
                        <b>Day Shift</b>
                      </Std>
                      <Std>07:00 - 15:15</Std>
                      <Std>07:00 - 15:15</Std>
                      <Std>07:00 - 15:15</Std>
                      <Std>07:00 - 15:15</Std>
                      <Std>07:00 - 14:45</Std>
                    </tr>
                    <tr>
                      <Std>
                        <b>Late shift</b>
                      </Std>
                      <Std>16:30 - 00:45</Std>
                      <Std>16:30 - 00:45</Std>
                      <Std>16:30 - 00:45</Std>
                      <Std>16:30 - 00:45</Std>
                      <Std>15:05 - 22:50</Std>
                    </tr>
                  </tbody>
                </TableEqualColumns>
                <br />
                <TableEqualColumns>
                  <thead>
                    <tr>
                      <Sth>Foam - 2 Shift</Sth>
                      <Sth>Monday</Sth>
                      <Sth>Tuesday</Sth>
                      <Sth>Wednesday</Sth>
                      <Sth>Thursday</Sth>
                      <Sth>Friday</Sth>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <Std>
                        <b>Day Shift</b>
                      </Std>
                      <Std>07:00 - 15:15</Std>
                      <Std>07:00 - 15:15</Std>
                      <Std>07:00 - 15:15</Std>
                      <Std>07:00 - 15:15</Std>
                      <Std>07:00 - 14:45</Std>
                    </tr>
                    <tr>
                      <Std>
                        <b>Late shift</b>
                      </Std>
                      <Std>15:10 - 23:25</Std>
                      <Std>15:10 - 23:25</Std>
                      <Std>15:10 - 23:25</Std>
                      <Std>15:10 - 23:25</Std>
                      <Std>14:40 - 22:25</Std>
                    </tr>
                  </tbody>
                </TableEqualColumns>
                <br />
                <h3>Pay rates</h3>
                <Col2>
                  <TableEqualColumns>
                    <thead>
                      <tr>
                        <Sth>2 Shift</Sth>
                        <Sth>Basic</Sth>
                        <Sth>Overtime 1</Sth>
                        <Sth>Overtime 2</Sth>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <Std>
                          <b>Under 6 weeks</b>
                        </Std>
                        <Std>£9.00</Std>
                        <Std>£11.97</Std>
                        <Std>£13.50</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Over 6 weeks</b>
                        </Std>
                        <Std>£9.64</Std>
                        <Std>£12.82</Std>
                        <Std>£14.46</Std>
                      </tr>
                    </tbody>
                  </TableEqualColumns>
                  <TableEqualColumns>
                    <thead>
                      <tr>
                        <Sth>3 Shift</Sth>
                        <Sth>Basic</Sth>
                        <Sth>Overtime 1</Sth>
                        <Sth>Overtime 2</Sth>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <Std>
                          <b>Under 6 weeks</b>
                        </Std>
                        <Std>£9.86</Std>
                        <Std>£13.12</Std>
                        <Std>£14.80</Std>
                      </tr>
                      <tr>
                        <Std>
                          <b>Over 6 weeks</b>
                        </Std>
                        <Std>£10.56</Std>
                        <Std>£14.05</Std>
                        <Std>£15.84</Std>
                      </tr>
                    </tbody>
                  </TableEqualColumns>
                </Col2>
                <p>
                  <b>OT1 –</b> Overtime worked Monday to Friday on day shift
                </p>
                <p>
                  <b>OT2 –</b> Overtime worked on a Saturday day shift or
                  weekday late shift and night shift
                </p>
                <p>
                  <b>OT3 –</b> Overtime worked on Sunday day shift
                </p>
              </FieldSet>
              <h2>Pre-Screen Questionnaire</h2>
              <FieldSet>
                <FormikText
                  label="1. Have you worked in the automotive industry before? Which companies, how long, which position?"
                  name="pre_screen_questionnaire.question_1"
                />
                <FormikText
                  label="2. Have you worked on a fast production line? Which companies, how long, which position?"
                  name="pre_screen_questionnaire.question_2"
                />
                <FormikText
                  label="3. Which other position have you applied for?"
                  name="pre_screen_questionnaire.question_3"
                />
                <FormikText
                  label="4. How long have you been unemployed and why?"
                  name="pre_screen_questionnaire.question_4"
                />
                <FormikText
                  label="5. How did you find out about this vacancy?"
                  name="pre_screen_questionnaire.question_5"
                />
                <FormikText
                  label="6. What do you know about Lear?"
                  name="pre_screen_questionnaire.question_6"
                />
                <FormikText
                  label="7. Have you ever worked at Lear before? If Yes - when was it, who was supervisor and why did you leave?"
                  name="pre_screen_questionnaire.question_7"
                />
                <FormikText
                  label="8. Are you flexible to work a three shift patterns (days, backs, nights)?"
                  name="pre_screen_questionnaire.question_8"
                />
                <FormikText
                  label="9. Are you flexible to work overtimes? Can you work overtime on Friday night/Saturday morning?"
                  name="pre_screen_questionnaire.question_9"
                />
                <FormikText
                  label="10. Do you have your own transport? If no how would you get to work?"
                  name="pre_screen_questionnaire.question_10"
                />
                <FormikText
                  label="11. Do you have any holidays booked?"
                  name="pre_screen_questionnaire.question_11"
                />
                <FormikText
                  label="12. Have you worked as a forklift driver before? Do you have a forklift license? How long for? Is it valid?"
                  name="pre_screen_questionnaire.question_12"
                />
              </FieldSet>
              <h2>Lear Absence Procedure</h2>
              <FieldSet>
                <p>
                  The absence procedure outlined below MUST be followed by all
                  Pin Point employees.
                </p>
                <p>
                  If you are unable to attend Lear for your shift, you must
                  call:
                </p>
                <Col2>
                  <CardGrey>
                    <b>Lauren Manson</b>
                    <span>
                      Mobile: <a href="tel: 07889537319">07889 537319</a>
                    </span>
                    <span>
                      Tel: <a href="tel: 01912615205">0191 261 5205</a>
                    </span>
                  </CardGrey>
                </Col2>
                <p>
                  <b>
                    You MUST call at least 1 hour prior to the start of your
                    shift and explain the reasons for your absence
                  </b>{" "}
                  and when you will return to work. If you are absent for more
                  than one day you must call every day until you return to work.
                </p>
                <p>
                  This standard absence procedure MUST be adhered to at all
                  times. Failure to do so may jeopardise your position at Lear.
                  It is also your responsibility to update Pin Point with any
                  changes to contact details.
                </p>
              </FieldSet>
              <h2>Line Trial Declaration</h2>
              <FieldSet>
                <p>
                  As part of the Recruitment and Selection process with Lear
                  Corporation you may be required to take part in an unpaid line
                  trial following completion of your registration.
                </p>
                <p>
                  Line Trials are used to determine whether the work carried out
                  by Lear Corporation is suitable for you and that you can
                  fulfil the duties expected of you during employment.
                </p>
                <FormikCheckbox
                  label="I  can confirm that I am happy to
                    participate in a line trial and understand that this will be
                    unpaid as it is part of my interview process"
                  name="happy_for_line_trial_participation"
                  bold={true}
                />
              </FieldSet>
              <FieldSet>
                <b>
                  By signing this document, you confirm that you have read and
                  understood the details of the role with Lear Corporation and
                  all points listed above have been discussed in full
                </b>
                <br />
                <br />
                <Signature
                  signatureDrawn={signatureDrawn}
                  setSignatureDrawn={setSignatureDrawn}
                />
                {errorText
                  ? errorText.map(e => (
                      <Error style={{ marginTop: "1rem" }} key={e}>
                        {e}
                      </Error>
                    ))
                  : null}
                <SButton type="submit" disabled={isSubmitting}>
                  Submit
                </SButton>
              </FieldSet>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}
export default LearNewStarter
